import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './locator.module.css'
import LinkIcon from './linkIcon'

const PageDetails = ({ data }) => {
  const { frontmatter } = data

  console.log(frontmatter)

  return (
    <div className={classNames('bg-paint-top-blue', styles.root)}>
      <h1
        className="visuallyhidden"
        dangerouslySetInnerHTML={{
          __html: frontmatter.title.toString().replace('\n', '<br />'),
        }}
      />
      <div className={styles.wrapper}>
        <section id="shop-online">
          <h2 className={classNames('title', styles.title)}>
            Shop online for Delivery
          </h2>
          <ul className={styles.list}>
            {frontmatter.purchase_links.map((link, index) => (
              <li key={index}>
                <LinkIcon label={link.label} />
              </li>
            ))}
          </ul>
        </section>
        <section id="locator">
          <h2 className={classNames('title', styles.title)}>Or Find a Store</h2>
          <iframe
            src="https://d3a4611dg8q0hq.cloudfront.net/kris/index.html"
            allow="geolocation"
            title="KRIS Wine Locator"
            className={styles.iframe}
          />
        </section>
      </div>
    </div>
  )
}

PageDetails.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PageDetails
